
  .my-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .search-container {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
  
  .button-container {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
  }
  
  #custom-button {
    /* border: 1px solid rgb(242, 9, 9); */
    /* background-color: white; */
    /* color: rgb(242, 9, 9); */
    margin: 0 5px;
    padding: 5px 10px;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Media Queries for Responsive Layout */
  @media screen and (max-width: 767px) {
    .button-container {
      flex-direction: column;
      align-items: center;
    }
  
    #custom-button {
      margin: 5px 0;
      width: 100%;
    }
  }
  