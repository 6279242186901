#resultbox{
    height:100px;
    width:210px;
    justify-content: space-around;
}
.card{
  border:3px solid #e9ecef
}
.settings-widget{
  border:3px solid #e9ecef

}
.noliststyle{list-style-type: none ;margin-top: 1em;margin-left: -.5em;}
.boxr{border-radius: 10px;  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);}
.boxText{
  font-size: 15px !important;
  color: #0B0B0B !important;
  margin-bottom: 20px !important;
}
.boxText1{
  font-size: 18px !important;
  color: #0B0B0B !important;
  margin-bottom: 20px !important;
}
@media screen and ( min-width:1025px) and (max-width: 2660px) {
  
  /* .krishna1{
height: 430px;
  }
  .krishna2{
    height: 390px;
      } */
}
.value:hover{
color: white;
}