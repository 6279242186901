ChatApp.css
.student-image {
    border-radius: 50%;
  }
  
  .student-image img {
    width: 80%;
    height: 80%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .student-sidebar {
    background-color: #f1f1f1;
    padding: 20px;
  }
  
  .student-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .student-item:hover {
    background-color: #e0e0e0;
  }
  
  .student-info {
    margin-left: 10px;
  }
  
  .active-symbol {
    color: green;
  }
  
  .chat-window {
    background-color: #ffffff;
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 20px;
  }
  
  .message-container {
    max-height: 450px;
    overflow-y: auto;
  }
  
  .message-input {
    margin-top: 20px;
    display: flex;
  }
  
  textarea {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #bdc3c7;
    border-radius: 5px;
  }
  
  .user-message {
     /* background-color: #3498db;  */
    color: #ecf0f1;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .student-message {
    /* background-color: #ecf0f1; */
    color: #34495e;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  /* Media query for responsiveness */
  @media (max-width: 992px) {
    .student-sidebar {
      margin-bottom: 20px;
    }
  }
  
  .message-container {
    display: flex;
    flex-direction: column;
    /* background-image: url("https://th.bing.com/th/id/OIP.uj9gFSI6xtlHg171rjh__gHaNK?w=208&h=326&c=7&r=0&o=5&dpr=1.3&pid=1.7"); */
    /* background-image: url("https://wallpapercave.com/wp/wp4892377.jpg"); */
    border-radius: 8px;
  }
  
  .user-message {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  
  .student-message {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    margin-left: 3px;
  }
  
  .sender-message {
    background-color: #1c8e06; 

    color: #ecf0f1;
    padding: 8px;
    border-radius: 8px;
    max-width: 70%;
    overflow-wrap: break-word;
  }
  
  .receiver-message {
    background-color:white ;  /* Blue color for sender messages */

    padding: 8px;
    border-radius: 8px;
    max-width: 70%;
    overflow-wrap: break-word;
    color: black;
  }
  
  /* .message-bubble {
    position: relative;
    background-color: #dcf8c6;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 5px;
    max-width: 70%;
  }
  
  .message-bubble::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -10px;
    border: 10px solid transparent;
    border-left-color: #dcf8c6;
    transform: translateY(-50%);
  } */
  