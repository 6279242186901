@media (max-width: 767px) {
  /* Adjust the form layout for mobile devices */
  .checkout-form .row {
    flex-direction: column;
  }

  .checkout-form .col-lg-6 {
    margin-bottom: 15px;
  }

  /* Add spacing between form fields in mobile view */
  .checkout-form .form-group {
    margin-bottom: 10px;
  }

  /* Adjust the position of the eye icon */
  .checkout-form .password-field span {
    left: auto;
    right: 10px;
  }

  /* Center-align the text in gender checkboxes */
  .checkout-form .form-group .form-check-inline {
    display: flex;
    align-items: center;
  }

  /* Increase the height of the gender checkboxes */
  .checkout-form .form-group .form-check-input {
    margin-top: 0;
  }

  /* Adjust spacing and alignment of buttons */
  .checkout-form .update-profile {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }

  .checkout-form .btn-group {
    flex: 1;
  }
  .form-checkk {
    margin-top: -50px;
  }
}
@media (min-width: 768px) and (max-width: 1023) {
  .main-page {
    width: 100%;
    /* margin-bottom:-10px; */

  }
}
@media (min-width: 740px) and (max-width: 2440px) {
  #custom-submit {
    width: 260px;
    /* margin-bottom:-10px; */

  }
}

/* Large devices (desktops) */
@media (max-width: 720px) {
  #custom-submit {
    width: 100%;
    /* margin-bottom:-10px; */
    /* color:"blue" */
  }
}
@media (max-width: 767px) {
  #submit-button {
      display: block;
      width: 100%;
      margin-bottom: 1em; 
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .main-page {
    width: 100%;
    /* margin-bottom:-10px; */

  }
}