
  
  /* Media queries for responsive behavior */
  @media (min-width: 768px) {
    .custom-div {
     padding : 10px
    }
  }
  
  /* Custom CSS for the heading animation */
/* @keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.live-classes-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 3px;
  white-space: nowrap; 
  overflow: hidden; 
  animation: slideInFromRight 0.6s ease; 
} */

  
  /* Add more media queries as needed for different screen sizes */
  #cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
  }
  #cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:#FF5364;
    border-top-color:transparent;
    border-width: 6px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }