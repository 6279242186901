@media (min-width: 720px) and (max-width: 2400px) {
    #img-grid {
     width: 389px;
      height: 271px;
      object-fit: cover;
      /* margin-bottom:-10px; */
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #img-grid {
      width: 100%;
      height: 100%;
      
      /* margin-bottom:-10px; */
    }
  }

  @media (min-width: 720px) and (max-width: 1024px) {
    #custom-button {
      width: 260px;
      /* margin-bottom:-10px; */
  
    }
    #button-btn{
        width:"260px"
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-button {
      width: 100%;
      /* margin-bottom:-10px; */
    }
    #button-btn{
        width: 100%;
    }
  }