.Modal.open{
    animation: bounce-in 1s ease-in;
}
.Modal.close{
    animation: bounce-out 1s ease-in;
}
@keyframes bounce-in{
    0%    {opacity: 0;   transform: scale(0.5);}
    100%  {opacity: 1;   transform: scale(1);}
}
@keyframes bounce-out{
    0%    {opacity: 1;   transform: scale(1);}
    100%  {opacity: 0;   transform: scale(0.5);}
}