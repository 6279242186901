.containerr {
    /* max-width: 80%;  */
    /* margin-left: -5px; */
     padding-left: 4px;
    /* padding-right: 15px;  */
    box-sizing: border-box; 
  } 

   @media (max-width: 992px) {
    .containerr {
      width: 80%;
    }
  }

  @media (max-width: 576px) {
    .containerr {
      width: 100%;
    }
  }
  @media (min-width: 740px) and (max-width: 1024px) {
    #custom-button {
      width: 260px;
      /* margin-bottom:-10px; */
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-button {
      width: 100%;
      /* margin-bottom:-10px; */
       color:"blue" 
    }
  }
  
  @media (min-width: 740px) and (max-width: 1440px) {
    #search-btn {
      width: 260px;
      /* margin-bottom:-10px; */
      color: blue;
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #search-btn {
      width: 100%;
      /* margin-bottom:-10px; */
       color:"blue" 
    }
  }