/* .card-pricing.popular {
    z-index: 1;
    border: 3px solid #007bff;
}
.card-pricing .list-unstyled li {
    padding: .5rem 0;
    color: #6c757d;
} */
#cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
  display:none;
}
#cover-spin::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color:#FF5364;
  border-top-color:transparent;
  border-width: 6px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
@keyframes spin {
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
@media (min-width: 720px) and (max-width: 2400px) {
    #img-grid {
     width: 100%;
      height: 271px;
      object-fit: cover;
      /* margin-bottom:-10px; */
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #img-grid {
      width: 100%;
      height: 100%;
      object-fit: cover;
      
      /* margin-bottom:-10px; */
    }
  }

  @media (min-width: 720px) and (max-width: 1024px) {
    #custom-button {
      width: 260px;
      /* margin-bottom:-10px; */
  
    }
    .button-btn{
        width:"260px"
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-button {
      width: 100%;
      /* margin-bottom:-10px; */
    }
    .button-btn{
        width: 100%;
    }
  }