.offcanvas-toggle {
    position: fixed;
    top: 50%;
    right: 50px;
    transform: translateY(-50%);
  }
  
  .offcanvas-container {
    position: relative;
  }
  
  .offcanvas {
    width: 400px;
  }
  
  .offcanvas-body {
    padding: 15px;
  }
  
  .offcanvas.show {
    opacity: 1;
  }
  
  .offcanvas {
    position: fixed;
    top: 0;
    right: 0;
    /* background:linear-gradient(to right, #bfcde2, rgb(200 212 202)); */
    background: #a4c0ea;

    height: 100%;
    /* width: 100%; */
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease;
    z-index: 1050;
  }
  
  .offcanvas.show {
    opacity: 1;
    visibility: visible;
  }
  

  .button-container {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }
  
  #custom-button {
    /* border: 1px solid rgb(242, 9, 9); */
    /* background-color: white; */
    /* color: rgb(242, 9, 9); */
    /* margin: 0 5px; */
    /* padding: 5px 10px; */
    text-decoration: none;
    cursor: pointer;
  }
  
  /* Media Queries for Responsive Layout */
  @media screen and (max-width: 767px) {
    .button-container {
      flex-direction: column;
      align-items: center;
    }
  
    #custom-button1 {
      margin: 5px 0;
      width: 100%;
    }
    
  }

  .question-container {
    text-align: left;
    margin-bottom: 20px;
  }
  
  .question {
    display: inline-block;
    margin: 0;
  }
  
  .question-text {
    display: inline-block;
    margin: 0;
    margin-left: 10px;
  }
  
  .options {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    margin-bottom: 10px;
  }
  
  .answer {
    margin-top: 10px;
  }

/* styles.css */

@media (min-width: 992px) {
  .offcanvas {
    display: block !important;
    transform: translateX(0);
    visibility: visible;
    opacity: 1;
  }
  
  .offcanvas-backdrop {
    display: none !important;
  }
}
@media (max-width: 576px) {
    .custom-offcanvas {
      max-width: 100%; /* For smaller screens, allow full width */
    }
  }
  
  @media (min-width: 577px) and (max-width: 992px) {
    .custom-offcanvas {
      max-width: 75%; /* Adjust the percentage as needed */
    }
  }
  
  @media (min-width: 993px) {
    .custom-offcanvas {
      max-width: 400px; /* Adjust this value for medium-sized screens */
    }
  }
  @media (max-width: 576px) {
    .custom-offcanvas {
      max-width: 100%; /* For smaller screens, allow full width */
    }
  }
  
  @media (min-width: 577px) and (max-width: 992px) {
    .custom-offcanvas {
      max-width: 75%; /* Adjust the percentage as needed */
    }
  }
  
  @media (min-width: 993px) {
    .custom-offcanvas {
      max-width: 400px; /* Adjust this value for medium-sized screens */
    }
  }
  .form-check-input{
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
  .form-check-label{
    margin-left:6px;
    margin-top:3px;
    color:black
  }

  .main-grid-1{
    background: linear-gradient(to right, #edc8bf, #dbe4dc),
  }
  