#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
  }
  #cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:#FF5364;
    border-top-color:transparent;
    border-width: 6px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
  .video-container {
    position: relative;
}

.video {
    width: 100%;
    height: 100%;
}

video::-webkit-media-controls-fullscreen-button
{
    display: none !important;
}

.fullscreen-button {
    position: absolute;
    top: 2%; left: 90%;
    z-index: 10;
}

.watermark {
    position: absolute;
    left: 3%; top: 3%;
    color: white;
    animation: 15s moving-watermark infinite;
}

@keyframes moving-watermark {
    0%   {left: 3%; top: 3%;}
    25% {left: 93%; top: 3%;}
    50% {left: 93%; top: 93%;}
    75% {left: 3%; top: 93%;}
    100% {left: 3%; top: 3%;}
}