@media (min-width: 768px) and (max-width: 1023) {
    .main-page {
      width: 100%;
      /* margin-bottom:-10px; */
  
    }
  }
  @media (min-width: 740px) and (max-width: 2440px) {
    #custom-submit {
      width: 260px;
      /* margin-bottom:-10px; */
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-submit {
      width: 100%;
      /* margin-bottom:-10px; */
      /* color:"blue" */
    }
  }
  @media (max-width: 767px) {
    #submit-button {
        display: block;
        width: 100%;
        margin-bottom: 1em; 
      }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    .main-page {
      width: 100%;
      /* margin-bottom:-10px; */
  
    }
  }