/* .timer-wrapper {
  position: absolute;
  top: 6px;
  right: 10px;
}

.mark {
  position: relative;
  right: 12em;
}
.custom-heading {
  margin-left: 29px;
}
.customHeading {
  margin-left: 29px;
}

.bigger-text {
  font-size: 3rem;
}

.add h1 {
  font-weight: 500;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 24px;
}
.text-increase p {
  font-size: 25px !important;
}

.text-question {
  font-weight: 600;
  font-size: 30px !important;
}

@media (max-width: 576px) {
  .rowB {
    margin-left: 160px;
    margin-top: -160px;
  }
  .next_btnn {
    margin-left: 115px;
    margin-top: 20px;
  }
  .question-panel {
    margin-left: 130px;
  }
}

@media (min-width: 577px) and (max-width: 991px) {
  .rowB {
    margin-left: 60px;
  }
  .question-panel {
    margin-left: 60px;
  }
}

@media (min-width: 992px) {
  
  .rowB {
    margin-left:330px;
    margin-top: -80px;
  }
  .child{
    margin-top: -50px;
    margin-left:170px;
  }
  .question-panel {
    margin-left: 260px;
    margin-top: -15px;
  }
}

.question-panel-row {
  display: flex;
  flex-wrap: wrap;
}

.question-panel-col {
  flex-basis: 50%;
  max-width: 50%;
}

@media (min-width: 768px) {
  .question-panel-row {
    display: block;
  }
  .question-panel-col {
    flex-basis: 100%;
    max-width: 100%;
  }

  
}
.parent {
  display: flex;
  flex-wrap: wrap;
}

.child {
  flex-basis: 50%;
  padding: 10px;
  box-sizing: border-box;
} */



/* .timer-wrapper {
  position: absolute;
  top: 6px;
  right: 10px;
}

.mark {
  position: relative;
  right: 12em;
}

.custom-heading {
  margin-left: 29px;
}

.customHeading {
  margin-left: 29px;
}

.bigger-text {
  font-size: 3rem;
}

.add h1 {
  font-weight: 500;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 24px;
}

.text-increase p {
  font-size: 25px !important;
}

.text-question {
  font-weight: 600;
  font-size: 30px !important;
}

.rowB {
  margin-left: 0;
  margin-top: 0;
}

.next_btnn {
  margin-left: 0;
  margin-top: 20px;
}

.question-panel {
  margin-left: 0;
}


@media (max-width: 576px) {
  .rowB {
    margin-left: 0;
    margin-top: 0;
  }

  .next_btnn {
    margin-left: 0;
    margin-top: 20px;
  }

  .question-panel {
    margin-left: 0;
  }
}


@media (min-width: 577px) and (max-width: 991px) {
  .rowB {
    margin-left: 0;
  }

  .question-panel {
    margin-left: 0;
  }
}


@media (min-width: 992px) {
  .rowB {
    margin-left: 0;
    margin-top: 0;
  }

  .child {
    margin-top: 0;
    margin-left: 0;
  }

  .question-panel {
    margin-left: 0;
    margin-top: 0;
  }
}

.question-panel-row {
  display: flex;
  flex-wrap: wrap;
}

.question-panel-col {
  flex-basis: 50%;
  max-width: 50%;
}

@media (min-width: 768px) {
  .question-panel-row {
    display: block;
  }
  .question-panel-col {
    flex-basis: 100%;
    max-width: 100%;
  }
}

.parent {
  display: flex;
  flex-wrap: wrap;
}

.child {
  flex-basis: 50%;
  padding: 10px;
  box-sizing: border-box;
} */
@media (min-width: 720px) and (max-width: 2400px) {
  .timer-wrapper {
    top: 10px;
    right: 10px;
    position: absolute;
  }
}

.timer-wrapper {
  /* position: absolute; */
  top: 6px;
  right: 10px;
}
@media (min-width: 320px) and (max-width: 720px) {
  .timer-wrapper {
    top: 10px;
    right: 10px;
  }
}
@media (min-width: 425px) and (max-width: 768px) {
  #ans-question{
margin-left: 20px;
  }
  #notans-question{
    margin-left: 7px;
    margin-top: 6px;
      }
      #notvisit-question{
        margin-left: 20px;
          }
          #mark-question{
            margin-left: 8px;
              }
}
@media (min-width: 769px) and (max-width: 1024px) {
  /* #ans-box{
    margin-left: 200px;
  } */
  #ans-question{
margin-left: 20px;
  }
  #notans-question{
    margin-left: 7px;
    margin-top: 6px;
      }
  #notvisit-question{
    margin-left: 20px;
        }
   #mark-question{
      margin-left: 8px;
           }
}
.mark {
  position: relative;
  right: 12em;
}

.custom-heading {
  margin-left: 29px;
}

.customHeading {
  margin-left: 29px;
}

.bigger-text {
  font-size: 3rem;
}

.add h1 {
  font-weight: 500;
  color: #000000;
  margin-bottom: 10px;
  margin-left: 24px;
}

.text-increase {
  font-size: 16px !important;
}

.text-question {
  font-weight: 600;
  font-size: 30px !important;
}

/* .rowB {
  margin-left: 0;
  margin-top: 0;
} */

.next_btnn {
  margin-left: 0;
  margin-top: 20px;
}

.question-panel {
  margin-left: 0;
}




@media (max-width: 576px) {
 
  #progressbar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .next_btnn {
    margin-left: 0;
    margin-top: 20px;
  }

  .customHeading {
    display: flex;
    flex-direction: row;
  }
  .child {
    width: 100%;
  }
  .childd {
    width: 100%;
  }

  .parent {
    display: flex;
    flex-direction: column;
  }

  .btn-info-light{
   
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }
}


@media (min-width: 577px) and (max-width: 991px) {
  .rowB {
    margin-left: 20px;
  }

.childd{
  width:500px;
}
  .custom-heading {
    margin-left: 30px;
    margin-top: 20px;
  }
  #progressbar{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

}


@media (min-width: 1200px) {
  .rowB {
    margin-left: 0px;
    margin-top: -80px;

  }

  .child {
    margin-top: -50px;
    margin-left: 160px;
    width: 800px;
  }

  .question-panel {
    margin-left: 0px;
    margin-top: -15px;
  }

  .childd{
    width: 600px;
  }
}

.question-panel-row {
  display: flex;
  flex-wrap: wrap;
}

.question-panel-col {
  flex-basis: 50%;
  max-width: 50%;
}

@media (min-width: 768px) {
  .question-panel-row {
    display: block;
  }

  .question-panel-col {
    flex-basis: 100%;
    max-width: 100%;
  }


}

.parent {
  display: flex;
  flex-wrap: wrap;
}

.child {
  flex-basis: 50%;
  padding: 10px;
  box-sizing: border-box;
}


.parent {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  /* overflow-x: auto; Enable horizontal scrolling */
}

.child {
  flex: 0 0 auto; /* Allow child elements to shrink or grow */
  white-space: nowrap; /* Prevent text from wrapping within child elements */
}
.button-container {
  display: flex;
  justify-content: center;
  flex-basis: 100%;
}

#custom-button {
  /* border: 1px solid rgb(242, 9, 9); */
  /* background-color: white; */
  /* color: rgb(242, 9, 9); */
  /* margin: 0 5px; */
  /* padding: 5px 10px; */
  text-decoration: none;
  cursor: pointer;
}

/* Media Queries for Responsive Layout */
@media screen and (max-width: 767px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }

  #custom-button1 {
    margin: 5px 0;
    width: 100%;
  }
  
}

/* Custom CSS for your layout */

/* On small screens, stack the sections on top of each other */
@media (max-width: 767px) {
  .col-lg-5 {
    width: 100%;
  }
  .buttons-section {
    margin-bottom: 20px; /* Add spacing between sections */
  }
}

/* On larger screens, display the sections side by side */
@media (min-width: 768px) {
  .col-lg-5 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .buttons-section {
    width: calc(50% - 10px); /* Adjust the width as needed */
  }
}

/* Style for labels and buttons within buttons-section */
.buttons-section label,
.buttons-section button {
  width: 100%; /* Ensure labels and buttons take full width */
  margin-top: 10px;
  text-align: center;
}

/* Custom CSS for Question Panel */
.category-tab.tickets-tab-blk {
  margin-top: 20px; /* Add spacing below buttons section */
}

.category-tab.tickets-tab-blk ul {
  list-style: none;
  padding: 0;
}

.category-tab.tickets-tab-blk li {
  width: 60px;
  height: 60px;
  text-align: center;
   margin-right: 10px;  
  /* Add spacing between items */
}
