@media screen and ( min-width:1024px) and (max-width: 2660px) {
  
    .graph1-ans{
     height: 395px;
    }
    .graph2-ans{
     height: 455px;
    }
    .graph2-1-ans{
       height: 455px;
      }
   }