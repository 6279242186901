.price-breakup-button {
    color: #ffffff;
    text-decoration: none;
    background-color: #0c3f79;
    border: none;
    padding: 0;
    font-size: 14px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .price-breakup-button:hover {
    color: #b87878;
  }
  
  .price-breakup-button:focus {
    outline: none;
  }

  #cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
  }
  #cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:#FF5364;
    border-top-color:transparent;
    border-width: 6px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }