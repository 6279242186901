.video-container {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 56.25%;
  };
  
  .video-container  {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
  }
   iframe {
width:100% ;
height: 630px ;
  }
  