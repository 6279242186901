.containerr {
    /* max-width: 80%; */
    margin-left: 5px;
    /* margin-right: auto; */
    /* padding-left: 15px;
    padding-right: 15px; */
    box-sizing: border-box;
  }

  @media (max-width: 992px) {
    .containerr {
      width: 80%;
    }
  }

  @media (max-width: 576px) {
    .containerr {
      width: 100%;
    }
    .ttt{
      width: 100%;
    }
  }