@media (min-width: 740px) and (max-width: 2440px) {
    #custom-submit {
      width: 260px;
      /* margin-bottom:-10px; */
  
    }
    #custom-cancel {
        width: 260px;
        /* margin-bottom:-10px; */
    
      }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-submit {
      width: 100%;
      /* margin-bottom:-10px; */
      /* color:"blue" */
    }
    #custom-cancel {
        width: 100%;
        /* margin-bottom:-10px; */
        /* color:"blue" */
        margin-top: 20px;
      }
  }
  @media (max-width: 767px) {
    #submit-button {
        display: block;
        width: 100%;
        margin-bottom: 1em;
      }
      #cancel-button {
        margin-top: 20px !important; 
      }
    
  }
