
  
  .button-conatiner {
    display: flex;
    flex-basis: 100%;
  }
  
  
  /* Media Queries for Responsive Layout */
  @media screen and (max-width: 767px) {
    .button-conatiner {
      flex-direction: column;
      align-items: center;
    }
  
    .custom-button {
      margin: 5px 0;
      width: 100%;
    }
    .custom-button-excel{
      width:100%;
    }
  }
 