/* For screen widths greater than 1024px */
@media (min-width: 1025px) {
    .scroll {
      height: 500px;
    }
  }
  
  /* For screen widths less than or equal to 1024px */
  @media (max-width: 1024px) {
    .scroll {
      height: 100%;
    }
  }
  