@media (min-width: 740px) and (max-width: 2440px) {
    #custom-button-1 {
      width: 170px;
      /* margin-bottom:-10px; */
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-button-1 {
      width: 100%;
      /* margin-bottom:-10px; */
    }
  }