.settings-menu .menu-item {
  cursor: pointer;
  height: 60px;
  background: #392c7d;
  border-radius: 5px;
}

/* .settings-menu .menu-item Link {

  background: #392c7d;
  color: white;
  border-radius: 5px;
  padding: 10px;
  transition: background 0.3s ease;
} */
.settings-widget11{
  border: none;
}
.nav-item.active {
  background-color: hsla(0, 1%, 30%, 0.25);
}
