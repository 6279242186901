.btnn {
    padding: 0.5em 1em;
    border-radius: 4px;
    color: green!important;
    border : 1px solid green;
  }

  .btnn:hover {
    color: white !important;
    background: green; 
    cursor: pointer;
  }

  .bt {
    padding: 0.5em 1em;
    border-radius: 4px;
    color: rgb(242, 9, 9) !important;
    border : 1px solid rgb(242, 9, 9) !important;
  }

  .bt:hover {
    color: white !important;
    background:rgb(242, 9, 9); 
    cursor: pointer;
  }
  .containerr {
    /* max-width: 80%; */
    margin-left: 5px;
    margin-right: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
    box-sizing: border-box;
  }

  @media (max-width: 992px) {
    .containerr {
      width: 80%;
    }
  }

  @media (max-width: 576px) {
    .containerr {
      width: 100%;
    }
  }
  .my-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .search-container {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
  
  .button-container-admin {
    display: flex;
    justify-content: center;
    flex-basis: 100%;
  }
  
  .custom-button-total {
    /* border: 1px solid rgb(242, 9, 9); */
    /* background-color: white; */
    /* color: rgb(242, 9, 9); */
    margin: 0 5px;
    padding: 5px 10px;
    text-decoration: none;
    cursor: pointer;
    background-color: white;
    border:  1px solid rgb(0, 128, 0);
     color: rgb(0, 128, 0);
     border-radius: 0.25rem;
  }
  .custom-button-free {
    /* border: 1px solid rgb(242, 9, 9); */
    /* background-color: white; */
    /* color: rgb(242, 9, 9); */
    margin: 0 5px;
    padding: 5px 10px;
    text-decoration: none;
    cursor: pointer;
    border:  1px solid rgb(242, 9, 9);
     color: rgb(242, 9, 9);
      background-color: white;
      border-radius: 0.25rem;

  }
  
  /* Media Queries for Responsive Layout */
  @media screen and (max-width: 767px) {
    .button-container-admin {
      flex-direction: column;
      align-items: center;
    }
  
    .custom-button-total {
      margin: 5px 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    .button-container-admin {
      flex-direction: column;
      align-items: center;
    }
  
    .custom-button-free {
      margin: 5px 0;
      width: 100%;
    }
  }
  @media screen and (min-width:1224px) {
    #table-value
    {
     overflow-x: hidden;
    }
  
   
  }
  @media screen and (min-width:1440px) {
  
  
    .custom-button1 {
      height:33px
    }
   
  }
  @media   (min-width:1024px) and (max-width:2660px) {
  
  .custom-button-excel {
      margin-right:.5em
    }
   
  }
  
  .custom-button-total:hover{
    color: white !important;
    background-color: rgb(0, 128, 0);
  }
  .custom-button-free:hover{
    color: white !important;
    background-color:rgb(242, 9, 9);
  }