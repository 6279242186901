.video-container {
  position: relative;
  width: 100%;
}
.h-550{
  height: 550px !important;
  width: 100% !important;
}

.video-container.fullscreen {
  position: relative;
  width: 100vw !important;
  height: 100vh !important;
  overflow: hidden;
}



.react-player {
  background-color: black;
  width: 100% !important;
  height: inherit !important;
  position: relative;
}
.react-player-fullscreen {
  position: absolute;
  background-color: black;
  width: 100% !important;
  height: 100vh !important;
}

.watermark {
  position: absolute;
  top: 10px; /* Starting position for watermark */
  left: 10px;  
  display: inline-block;
  width: 120px;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.326);
  color: rgba(255, 255, 255, 0.4); /* White color with transparency */
  z-index: 10;
  animation: moveWatermark 50s infinite linear; /* Move watermark around */
}

@keyframes moveWatermark {
  0% {
    top: 100px;
    left: 100px;
  }
  25% {
    top: 100px;
    left: 65%;
  }
  50% {
    top: 400px;
    left: 65%;
  }
  75% {
    top: 400px;
  }
  100% {
    top: 100px;
    left: 100px;
  }
}

.controls{
  position: relative;
  bottom: 70px;
  height: 70px;
  background-color: rgba(13, 14, 13, 0.308);
  border-radius: 10px;
  z-index: 100;
}

input[type='range']{ accent-color: #8b5cf6; }


.bg-overlay{
  background-color: rgba(21, 21, 21, 0.447);
}

@keyframes moveLeftToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%); /* Adjust based on desired distance */
  }
}

.forwardStatusIcon {
  position: absolute;
  animation: moveLeftToRight 1s forwards; /* 1s for animation duration */
}
@keyframes moveRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0); /* Adjust based on desired distance */
  }
}

.backwardStatusIcon {
  position: absolute;
  animation: moveRightToLeft 1s backwards; /* 1s for animation duration */
}
