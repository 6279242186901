.containerr {
    /* max-width: 80%; */
    /* margin-left: -5px; */
     padding-left: 15px;
    /* padding-right: 15px;  */
    box-sizing: border-box;
  }

  @media (max-width: 992px) {
    .containerr {
      width: 80%;
    }
  }

  @media (max-width: 768px) {
    .containerr {
      width: 100%;
      height: 70px;
    }

    .main-wrapper{
      width: 100%; 
      padding-left: 0; 
      padding-right: 0; 
      margin-left: 0;
      margin-right: 0;
    }

  }
 /* Custom classes for button width based on screen size */
/* Small devices (landscape phones) */


/* Medium devices (tablets) */
@media (min-width: 740px) and (max-width: 1024px) {
  #custom-button {
    width: 280px;
    /* color:aqua */
    margin-bottom:-10px;

  }
}

/* Large devices (desktops) */
@media (max-width: 720px) {
  #custom-button {
    width: 100%;
    margin-bottom:-10px;
  }
}
