@media screen and ( min-width:1024px) and (max-width: 2660px) {
  
   .graph1{
    height: 345px;
   }
   .graph2{
    height: 415px;
   }
   .graph2-1{
      height: 415px;
     }
  }
  /* .krishna1{
   height: 500px;
  } */
  @media screen and ( min-width:1024px) and (max-width: 2660px) {
  
   .single-1{
height:460px
   }
  }
  