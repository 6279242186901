.product-five-title Link {
    font-weight: 900;
    font-size: 45px;
}

/* @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  .live-classes-heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 3px;
    white-space: nowrap;
    overflow: hidden;
    animation: slideInFromRight 0.6s ease; 
  }
   */
   #cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
  }
  #cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:#FF5364;
    border-top-color:transparent;
    border-width: 6px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
  }
  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
.test1-1{
  background-color: #FF875A;
}
.course-three-content > .test1-1{
  background-color: white;
  color:blue;
}
.col-container {
  display: grid;
  grid-auto-rows:1fr; /* this will make sure the height is the same even after wrapping*/
  grid-template-columns:repeat(auto-fit,minmax(25rem,1fr));
}
@media screen and (max-width: 767px) {
  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .custom-button {
    margin: 5px 0;
    width: 100%;
  }
}
@media screen and (max-width: 1023px) {
  .button-container-student {
    flex-direction: column;
    align-items: center;
  }

  .custom-button-start {
    margin: 5px 0;
    width: 100%;
  }
}
.test1{
  width:"200px"
}
/* .course-three-content >.course-three-text >.student-counts-info >.students-three-counts> .ptag:hover{
  font-size: 15px;
} */








/* length==0
||
(it?.submoduleattempts!==null)?it?.test_result[0]?.attempt<it?.submoduleattempts:it?.test_result[0].attempts<it.submoduleattempts
 ||
 it?.coursemoduleattempts!==null?it?.test_result[0]?.attempt<it?.coursemoduleattempts:it?.test_result[0].attempts<it.coursemoduleattempts
  ||
it?.test_result[0]?.attempt<it?.submoduleattempts */