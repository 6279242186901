@media (min-width: 720px) and (max-width: 2400px) {
  #img-grid {
   width: 301px;
    max-height: 160px;
    object-fit: cover;
    /* margin-bottom:-10px; */

  }
}

/* Large devices (desktops) */
@media (max-width: 720px) {
  #img-grid {
    width: 100%;
    height: 100%;
    
    /* margin-bottom:-10px; */
  }
}

@media (min-width: 720px) and (max-width: 1024px) {
  #custom-button {
    min-width: none;
    /* margin-bottom:-10px; */
    /* background-color: aqua; */
    color:white

  }
}
@media (min-width: 1025px) and (max-width: 2600px) {
  #filter {
    margin-top: -36px;

  }
}

@media  (max-width: 720px)  and (min-width:240px){
  #filter {
    margin-top: 0px;

  }
}
/* Large devices (desktops) */
@media (max-width: 720px) {
  #custom-button {
    width: 100%;
    /* margin-bottom:-10px; */
  }
}
@media (min-width: 991px) and (max-width: 1027px) {
  #buy {
    min-width: fit-content;
  }
}

@media (min-width: 991px) and (max-width: 1027px) {
  #know {
    min-width: fit-content;
    /* margin-bottom:-10px; */
  }
}
#buy{
  min-width: 0px;
}
#know{
  min-width: 0px;
  border-color: #FF5364 !important;
  color: #f3182e !important;
  
}

#cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
  display:none;
}
#cover-spin::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color:#FF5364;
  border-top-color:transparent;
  border-width: 6px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}
#buy:hover{
  background-color: FF5364 !important;
  color:white;
  border:2px solid #B4A7F5 ;
}
#know:hover{
  background-color: #FF5364 !important;
  color: white  !important;
  border:2px solid  ;
}
@media ( (min-width:1024px) and (max-width: 2440px)) {
  .datavalue {
  margin-top: -30px;
    /* margin-bottom:-10px; */
  }
}
