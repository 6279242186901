.single-line {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  
  .actives {
    position: relative; 
  }
  
  
  .actives::before {
    content: '';
    position: absolute;
    top: 80%;
    left: 0;
    transform: translateY(-50%);
    width: 100%;
    height: 3px; 
    background-color: rgb(231, 106, 106); 
  }