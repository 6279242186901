@media (min-width: 720px) and (max-width: 2400px) {
  #img-grid {
   width: 307px;
    height: 207px;
    object-fit: cover;
    /* margin-bottom:-10px; */

  }
}

/* Large devices (desktops) */
@media (max-width: 720px) {
  #img-grid {
    width: 100%;
    height: 100%;
    
    /* margin-bottom:-10px; */
  }
}

@media (min-width: 720px) and (max-width: 1024px) {
  #custom-button-1 {
   min-width: 0px
    /* margin-bottom:-10px; */

  }
  .button-btn{
      width:"260px"
  }
}

/* Large devices (desktops) */
@media (max-width: 720px) {
  #custom-button-1 {
    width: 100%;
    /* margin-bottom:-10px; */
  }
  .button-btn{
      width: 100%;
  }
}
#cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
  display:none;
}
#cover-spin::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color:#FF5364;
  border-top-color:transparent;
  border-width: 6px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}
@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}
#custom-button-1{
  border-color: #FF5364 !important;
  color: #f3182e !important;
}
#custom-button-1:hover{
  background-color: #FF5364 !important;
  color: white  !important;
  border:2px solid #FF5364 ;
}