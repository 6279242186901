/* .App {
    display: flex;
    justify-content: space-around;
    height: 100vh;
  } */
  
  /* .data-container,
  .drop-container {
    flex: 3;
  } */
  
  .source-container {
    /* display: flex; */
    flex-wrap: wrap;
  }
  
  .box {
   
    border: 1px solid #e9ecef;;
    margin: 10px;
    display: inline-block;
    text-align: center;
    padding:10px;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  
  .source {
    cursor: move;
  }
  
  .target {
    padding: 10px;
    /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;  */
    /* height: 100%; */
  }
  
  .dropped-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    /* box-shadow: rgba(231, 231, 235, 0.2) 0px 7px 29px 0px; */
}
  