@media (min-width: 740px) and (max-width: 1024px) {
    #custom-button {
      width: 260px;
      /* margin-bottom:-10px; */
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-button {
      width: 100%;
      /* margin-bottom:-10px; */
       color:"blue" 
    }
  }
  
  @media (min-width: 740px) and (max-width: 1440px) {
    #search-btn {
      width: 260px;
      /* margin-bottom:-10px; */
      color: blue;
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #search-btn {
      width: 100%;
      /* margin-bottom:-10px; */
       color:"blue" 
    }
  }