.containerr {
    /* max-width: 80%; */
    margin-left: 5px;
    margin-right: auto;
    /* padding-left: 15px;
    padding-right: 15px; */
    box-sizing: border-box;
  }

  @media (max-width: 992px) {
    .containerr {
      width: 80%;
    }
  }

  @media (max-width: 576px) {
    .containerr {
      width: 100%;
    }
  }
  @media (min-width: 740px) and (max-width: 1024px) {
    #custom-button {
      width: 260px;
      margin-bottom:-10px;
  
    }
  }
  
  /* Large devices (desktops) */
  @media (max-width: 720px) {
    #custom-button {
      width: 100%;
      margin-bottom:-10px;
      /* color:blue" */
    }
    .custom-button-restircted {
      width: 100%;
      /* color:blue" */
    }
    .custon-button-manage{
      width: 100%;
      /* color:blue" */
    }
  }
  .custom-button-restircted{
    color: white;
       text-align: center;
       background-color: #FF5364;
       margin-right:'.3em';
       border: 1px solid #FF5364;
       border-radius: 4px;
  }
  .custon-button-manage{
    color: white;
    text-align: center;
    background-color: #FFD600;
    margin-right:'.3em';
    border: 1px solid #FFD600;
    border-radius: 4px;
  }