#resultbox{
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding-top:10px;
    padding-bottom:20px;
    border-radius:4px;
    /* width:200px; */
    border:0.1px solid #a19a9a;
    margin-top: 10px
};
#resultsvalue{
    font-weight:700 ;
    text-align:center;
    padding:5px;
    font-size:28px;

}